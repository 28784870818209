body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.signInText{
  color:#707070;
  text-align: left;
  margin: 30px;
}

.loginForm{
  
  margin: 30px;
}

.formInput{
  text-align: left;
}
.navbarHeader{
  background: #62A3F9;
}


.navbar-light .navbar-toggler-icon{
  background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkAQAAAADJe6U1AAAAAnRSTlMAAHaTzTgAAAAVSURBVHgBYyAV8P///x+ZpLl68gAA5l8bbe6e1/kAAAAASUVORK5CYII=) !important;
}
.navbar-light .navbar-toggler{
  border-color: transparent !important;
  outline: transparent !important;
}

.logoImg{
  width: 120px;
}
.mainText{
    margin: 30px;
    text-align: left
}

.signInbtn {
  background-color: #62A3F9 !important;
  border-color: #62A3F9 !important;
  width: 147px !important;
  padding: 13px !important;
}

.forgot{
  color: #62A3F9;
  padding: 20px;
  cursor: pointer;
}

.userHomeText{
  margin: 20px;
  text-align: left;
}

input[type="checkbox"]{
  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
  cursor: pointer;
}

.checkBoxLablel{
   position: relative;
   left: 30px;
   top:8px;
}



.checkboxInput li{
  list-style: none;
  text-align: left;
  margin: 20px;
  cursor: pointer;
}

.homeBottomText{
  text-align: left;
    margin: 28px;
}

.bottomMenu{
  text-align: left;
}

.navbar-light .navbar-nav .nav-link{
  color: #fff !important;
  cursor: pointer !important;
}

.payment_main_div {padding: 20px 20px;}
.backButton button {padding: 0;color:#62A3F9;background-color: transparent;border: unset;font-size: 13px;font-weight: 600;}
.backButton img {width: 12px;}
.backButton {text-align: left;display: -webkit-flex;display: flex;-webkit-align-items: center;align-items: center;}
.awsome_billing_div {text-align: center;padding: 20px 0;}
.awsome_billing_div p { font-size: 14px; color: #777; font-weight: 400; margin-bottom: 0;}
.awsome_billing_div p:nth-child(2) {color: #000;}
.check_box_payment {text-align: left;margin: 0 auto 17px; width: 290px;}
span.break_text {display: block;font-size: 15px;font-weight: 600;}
span.change_color {color: #62A3F9;}
.checkBoxLablel b {font-size: 16px;}
.mainHeadingBillingInfo{text-align: left;}
.inputSize{width:65% !important;}
.inputSizeShort{width:30% !important;}
.expireDate{display: block; width: 100%;height: calc(1.5em + .75rem + 2px); padding: .375rem .75rem;font-size: 1rem;font-weight: 400;line-height: 1.5; color: #495057;background-color: #fff;background-clip: padding-box; border: 1px solid #ced4da;border-radius: .25rem;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.payBtn{
  width:100%;
  padding: 10px;
  background: #62A3F9;
  border-color: #62A3F9;
  color: #fff;
}

.navlist{
  list-style: none;
}


.proccessing_div {
  background-color: #fff;
  position: relative;
  z-index: 10000;
  height: 200px;
  width: 200px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 20px;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.proccessing_sec {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: rgba(0,0,0,0.8);
}

.successText{
  margin-top: 58px;
  text-align: center;
}

.returnBtn{
  padding: 15px;
  margin-top: 24px;
  background-color: #62A3F9;
  color: #fff;
}



